.prompt {
    margin: 5px;
    min-height: 15vh;
    min-width: 50vw;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.prompt-valid:focus {
    outline: dodgerblue solid thick;
}

.prompt-invalid:focus {
    outline: indianred solid thick;
}

.input2 {
    height: 200px;
}
